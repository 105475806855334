import React, { useRef, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CSectTitle03,
  CMedia,
  CHorizCard,
  CHeroImg,
  CCard,
  CFaqList,
  CSearch,
  CInlineDefinition,
  CRoomDesc,
  CRoomDesc02,
  CSpChangeImg,
  LContact03,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: '朝食',
            sub: <>BREAKFAST</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/breakfast/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/breakfast/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <CSearch formId="global" />
      <section className="l_sect">
        <LWrap>
          <CSectTitle03 title="エグゼクティブラウンジ" />
          <CSpChangeImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/breakfast/img_breakfast.png',
              alt: '',
            }}
            imgSp={{
              src: '/assets/images/stay/breakfast/img_breakfast__sp.png',
              alt: '',
            }}
          />
          <CRoomDesc02
            style={{ justifyContent: 'space-between', alignItems: 'normal' }}
            left={
              <>
                <h3 className="c_headingLv2 u_tal u_fwm">
                  スイート、エグゼクティブフロアにご宿泊のお客様
                </h3>
                <p className="u_mb20">
                  20F専用ラウンジの朝食ブッフェをご利用いただけます。
                </p>
                <ul className="c_noteList">
                  <li>
                    営業時間を変更する場合がございますので、ご了承ください。
                  </li>
                  <li>
                    1F シェフズダイニング
                    シンフォニーのご朝食をご希望の場合は特別料金2,000円にてご利用いただけます。
                  </li>
                  <li>料金には消費税・サービス料が含まれます。</li>
                </ul>
              </>
            }
            right={
              <>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '時間',
                      text: <>7:00~10:00</>,
                    },
                  ]}
                />
              </>
            }
          />
          <CBtnList
            data={[
              {
                label: 'エグゼクティブラウンジ',
                link: {
                  href: '/stay/floor/lounge/',
                },
                color: 'borderBlack',
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge">
        <LWrap>
          <CSectTitle03 title="シェフズダイニング シンフォニー" />
          <CSpChangeImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/breakfast/img_breakfast02.png',
              alt: '',
            }}
            imgSp={{
              src: '/assets/images/stay/breakfast/img_breakfast02__sp.png',
              alt: '',
            }}
          />
          <CRoomDesc02
            style={{ justifyContent: 'space-between', alignItems: 'normal' }}
            left={
              <>
                <p className="u_mb20">
                  窓越しに望む滝の流れを感じながら、ゆったりとご朝食をお楽しみください。ブッフェは、パンやシリアル、シェフが目の前で作り上げるオムレツ、目玉焼きのほか、ワッフル、サラダ、ピラフ、ポテト、スープなど品数豊富なメニューで、どなたにもご満足いただけます。
                </p>
                <ul className="c_noteList">
                  <li>
                    ご朝食のご予約は承っておりません。週末を中心に混雑することがございますので、なるべくお早めの時間帯でのご利用をおすすめいたします。
                  </li>
                </ul>
              </>
            }
            right={
              <>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: 'ブッフェ',
                      text: (
                        <>
                          6:30~10:00 <br className="u_sp" />
                          （最終入店9:30）
                        </>
                      ),
                    },
                    {
                      title: 'セットメニュー',
                      text: <>6:30~11:00</>,
                    },
                  ]}
                />
              </>
            }
          />
          <CBtnList
            data={[
              {
                label: 'シンフォニー朝食のご案内',
                link: {
                  href: '/restaurants/symphony/',
                },
                color: 'borderBlack',
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge">
        <LWrap>
          <CSectTitle03 title="ルームサービス" />
          <CSpChangeImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/stay/breakfast/img_breakfast03.png',
              alt: '',
            }}
            imgSp={{
              src: '/assets/images/stay/breakfast/img_breakfast03__sp.png',
              alt: '',
            }}
          />
          <CRoomDesc
            style={{
              justifyContent: 'space-between',
              alignItems: 'normal',
              gap: '80px',
            }}
            left={
              <>
                <p className="u_mb20">
                  プライベートな空間で、ゆったりと朝のひとときをお過ごしください。
                </p>
              </>
            }
            right={
              <>
                <CInlineDefinition
                  col={1}
                  data={[
                    {
                      title: '時間',
                      text: <>6:30~11:30</>,
                    },
                  ]}
                />
              </>
            }
          />
          <CBtnList
            data={[
              {
                label: 'ルームサービス',
                link: {
                  href: '/private/3mchpxdzfuxz/',
                },
                color: 'borderBlack',
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title="お問合せ"
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-3667-1111',
          subText: '（代表）',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
